<template>
  <div v-editable="blok">
    <Slider
      :blok="settings"
      class="-mx-2 blog-slider"
      :class="{ 'carousel-buttons-disabled': buttonsDisabled }"
    >
      <slide v-for="(item, i) in items" :key="i" class="px-2">
        <Card
          :headline="item.name"
          :headline-tag="blok?.headline_tag"
          :link="link(item).link"
          :image="{
            src: item.content.image.filename,
            alt: item.content.image.alt,
          }"
          class="mx-2 blog-slider-card"
        >
          <!-- <div class="feature-bento-box-summary">

            <p>{{ item.summary }}</p>
            <p>{{ formateDate(item.date) }}</p>
          </div> -->
        </Card>
      </slide>
    </Slider>
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object });
const { items, all, show, link, setResource, countTotalItems } = useMedia();
setResource("blog");

if (props.blok.items?.length) {
  const links = await countTotalItems();

  const getItemId = (uuid) => {
    return links[uuid]?.id;
  };

  props.blok.items.forEach(async (storyOrUuid) => {
    const story =
      typeof storyOrUuid === "object"
        ? storyOrUuid
        : await show(getItemId(storyOrUuid));

    if (
      story.lang === "default" ||
      story.translated_slugs.find(
        (slug) => slug.lang === story.lang && slug.published,
      )
    ) {
      items.value.push(story);
    }
  });
} else {
  const initalItems = await all(
    { page: 1, per_page: props.blok.limit },
    props.blok?.keyword,
  );
  items.value.push(...initalItems);
}

const maxItemsToShow = computed(() => parseInt(props.blok.items_to_show) || 2);
const buttonsDisabled = computed(
  () => items.value?.length <= maxItemsToShow.value,
);

const breakpoints = computed(() => ({
  640: {
    itemsToShow: 2,
    itemsToScroll: 2,
    snapAlign: "start",
  },
  769: {
    itemsToShow: 3,
    itemsToScroll: 3,
    snapAlign: "start",
  },
  1000: {
    itemsToShow: maxItemsToShow.value,
    itemsToScroll: maxItemsToShow.value,
    snapAlign: "start",
  },
}));

const settings = computed(() => ({
  itemsToShow: 1,
  itemsToScroll: 1,
  snapAlign: "start",
  navigation: true,
  pagination: false,
  breakpoints: breakpoints.value,
}));
</script>

<style lang="scss">
.feature-bento-box.blog-slider-card {
  padding: 10px;
}
.blog-slider {
  h3 {
    font-size: 1.2rem;
  }
}
</style>
